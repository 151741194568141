import './prepare';
import {startApplication} from './startApp';
import {addThemes} from './addThemes';

fetch('/sitedata.json')
  .then((res) => res.json())
  .then((siteData) => {
    addThemes(siteData);

    startApplication(siteData.widgetTree);
  });
